<template>
    <div class="stepper-content">
        <div class="stepper-content_wrapper">
            <template v-for="(item, key) in filteredSteps" :key="item.title">
                <div class="step">
                    <div class="image" :class="item.imageStyle">
                        <img
                            :src="step >= key + 1 ? item.image : item.inactive"
                            :alt="`icono de ${item.title}`"
                        />
                    </div>
                    <div class="text">{{ item.title }}</div>
                </div>
                <div
                    v-if="steps.length - 1 !== key"
                    :key="key"
                    :class="step >= key + 1 ? 'border-site-primary' : 'border-gray-400'"
                    class="line"
                ></div>
            </template>
        </div>
    </div>
</template>

<script lang="ts" setup>
const { $assets, $lang } = useNuxtApp()

const props = defineProps({
    // @model Recibe mini o normal.
    model: {
        type: String,
        default: 'normal',
        required: true,
    },
    // Step actual 1-2-3 || 1-2 en el caso de que model = mini
    step: {
        type: Number,
        default: 1,
        required: true,
    },
})

const steps = [
    {
        title: $lang.components.microStepper.share,
        image: $assets.share.stepper.first,
        inactive: $assets.share.stepper.firstInactive,
        imageStyle: 'mb-1',
    },
    {
        title: $lang.components.microStepper.description,
        image: $assets.share.stepper.second,
        inactive: $assets.share.stepper.secondInactive,
        imageStyle: 'mt-1',
    },
    {
        title: $lang.components.microStepper.finished,
        image: $assets.share.stepper.third,
        inactive: $assets.share.stepper.thirdInactive,
        imageStyle: 'mb-1',
    },
]

const filteredSteps = computed(() => {
    const newSteps = steps
    if (props.model === 'mini') {
        newSteps.shift()
    }
    return newSteps
})
</script>

<style lang="postcss" scoped>
.stepper-content {
    @apply flex justify-center;
    &_wrapper {
        @apply mx-4 flex w-full max-w-lg items-center p-5;
        .step {
            @apply relative flex items-center text-site-primary;
            .image {
                @apply mx-auto mb-4 h-12 w-12 border-site-primary py-3 transition duration-500 ease-in-out;
            }
            .text {
                @apply absolute top-0 -ml-10 mt-16 w-32 text-center text-base font-medium text-gray-900;
            }
        }
        .line {
            @apply mx-2 flex-auto border-t-2 transition duration-500 ease-in-out;
        }
    }
}
</style>
